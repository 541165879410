@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Reg.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Sbold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: "Proxima Nova" !important;
}

.MuiTypography-root {
  font-family: "Proxima Nova" !important;
}

html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

a {
  outline: none;
}

html ::-webkit-scrollbar {
  width: 6px !important;
  scroll-behavior: smooth !important;
  height: 7px;
  margin: -1px;
}
html ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 3px !important;
}
html ::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  border-radius: 3px !important;
}
html ::-webkit-scrollbar-corner {
  background-color: transparent;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.vendor-card__description p {
  margin: 4px 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
